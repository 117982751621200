<template>
  <div class="dysfunctions q-w374 q-fit">
    <div class="dysfunctions__title">
      {{ $t('mySkinDysfunctions.introText', [doctorFullName]) }}
    </div>

    <div class="dysfunctions__list dysfunctions-list q-mt32 q-mt40-md">
      <div
        v-for="{ value, name, title, color } in patientSkinDysfunctions"
        :key="color"
        class="dysfunctions-list__item q-mb40"
      >
        <colored-slider
          :value="value"
          :title="$t(title)"
          :color="color"
          :options="$options.sliderOptions"
          @input="onFieldChange(name, $event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ColoredSlider from '@/modules/questionnaire/components/input/ColoredSlider';

import { stepTemplateMixin } from '@/modules/questionnaire/mixins/stepTemplateMixin';

const SKIN_DYSFUNCTIONS_CONFIG = {
  skinAppearanceDiscomfort: {
    title: 'skinDysfunctions.skinAppearanceDiscomfort',
    color: 'skin-appearance'
  },
  inflammationDiscomfort: {
    title: 'skinDysfunctions.inflammationDiscomfort',
    color: 'skin-redness'
  },
  drynessDiscomfort: {
    title: 'skinDysfunctions.drynessDiscomfort',
    color: 'skin-dryness'
  },
  greasySkinDiscomfort: {
    title: 'skinDysfunctions.greasySkinDiscomfort',
    color: 'skin-oiliness'
  },
  textureDiscomfort: {
    title: 'skinDysfunctions.textureDiscomfort',
    color: 'skin-texture'
  },
  acneDiscomfort: {
    title: 'skinDysfunctions.acneDiscomfort',
    color: 'pimples'
  },
  hyperpigmentationDiscomfort: {
    title: 'skinDysfunctions.hyperpigmentationDiscomfort',
    color: 'skin-pigmentation'
  }
};

const SLIDER_OPTIONS = [
  {
    label: 'skinDysfunctions.low',
    value: 'low',
    numericValue: 0
  },
  { label: 'skinDysfunctions.high', value: 'high', numericValue: 50 },
  {
    label: 'skinDysfunctions.veryHigh',
    value: 'very_high',
    numericValue: 100
  }
];

export default {
  name: 'SkinDysfunctionsTemplate',
  components: { ColoredSlider },
  mixins: [stepTemplateMixin],
  sliderOptions: SLIDER_OPTIONS,
  props: {
    dysfunctions: {
      type: Object,
      required: true
    },
    doctorFullName: {
      type: String,
      default: ''
    }
  },
  computed: {
    patientSkinDysfunctions() {
      return Object.entries(this.dysfunctions).map(([name, value]) => ({
        ...SKIN_DYSFUNCTIONS_CONFIG[name],
        name,
        value
      }));
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~universkin-shared/src/assets/styles/scss/sizes.scss';

.dysfunctions {
  &__title {
    margin: 16px auto 0 auto;
    font-family: var(--u-font-family-Gilroy);
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: center;
  }
}

@media (min-width: $desktop-start) {
  .dysfunctions {
    &__title {
      font-family: var(--u-font-family-Helvetica);
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }
  }
}
</style>
