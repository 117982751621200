<template>
  <div class="colored-slider" :style="sliderStyles">
    <label :for="sliderId" class="colored-slider__title u-typography-helvetica u-text u-text--m">
      {{ title }}
    </label>

    <div class="colored-slider-input-wrap colored-slider__input-wrap">
      <input
        :id="sliderId"
        type="range"
        class="colored-slider-input-wrap__input"
        :value="numericValue"
        :disabled="disabled"
        min="0"
        max="100"
        :step="sliderStep"
        :aria-valuetext="textValue"
        :aria-valuenow="numericValue"
        aria-valuemin="0"
        aria-valuemax="100"
        :aria-label="title"
        @input="onChange(+$event.target.value)"
      />
    </div>

    <div class="colored-slider__labels slider-labels u-typography-helvetica u-text u-text--xs">
      <span
        v-for="{ label, value: optionValue } of options"
        :key="optionValue"
        class="slider-labels__label"
        :class="getSliderLabelClass(optionValue)"
        @click="$emit('input', optionValue)"
      >
        {{ $t(label) }}
      </span>
    </div>
  </div>
</template>

<script>
import { getUniqueElementId } from '@/utils/getUniqueElementId';

export default {
  name: 'ColoredSlider',
  props: {
    value: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      required: true
    },
    options: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      sliderId: null
    };
  },
  computed: {
    sliderStyles() {
      return {
        '--slider-color': `var(--u-color-${this.color}-500)`,
        '--slider-fill-width': `${this.numericValue}%`,
        '--options-length': this.options.length
      };
    },
    sliderStep() {
      return 100 / (this.options.length - 1);
    },
    selectedOption() {
      return this.options.find(({ value }) => value === this.value);
    },
    numericValue() {
      return this.selectedOption.numericValue;
    },
    textValue() {
      return this.$t(this.selectedOption.label);
    }
  },
  created() {
    this.sliderId = getUniqueElementId('colored-slider');
  },
  methods: {
    onChange(newNumericValue) {
      const { value } = this.options.find(({ numericValue }) => numericValue === newNumericValue);

      this.$emit('input', value);
    },
    getSliderLabelClass(value) {
      return {
        'slider-labels__label--active': this.value === value
      };
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~universkin-shared/src/assets/styles/scss/sizes.scss';

.colored-slider {
  &__title {
    margin-bottom: 16px;
    color: var(--slider-color);
    display: block;
  }

  &__input-wrap {
    position: relative;
    box-sizing: border-box;
    height: 4px;
    z-index: 0;

    &::after {
      position: absolute;
      top: 0;
      left: 0;
      content: '';
      /* TODO: Autoprefixer throws a false warning because of custom variable usage inside calc() function.
         Update autoprefixer for removing the warning https://jira.andersenlab.com/browse/UNK-6597 */
      width: calc(var(--slider-fill-width) - 6px);
      height: 100%;
      background-color: var(--slider-color);
      border-radius: 12px;
      z-index: -1;
    }
  }
}

.colored-slider-input-wrap {
  line-height: normal;

  &__input {
    -webkit-appearance: none;
    width: 100%;
    display: inline-block;
    margin: 2px;
    background: transparent;

    &:focus {
      outline: none;
    }

    &::-ms-track {
      width: 100%;
      cursor: pointer;
      background: transparent;
      border-color: transparent;
      color: transparent;
    }

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      height: 28px;
      width: 28px;
      border: 4px solid var(--u-color-white);
      border-radius: 50%;
      background: var(--slider-color);
      cursor: pointer;
      margin-top: -14px;
    }

    &::-moz-range-thumb {
      height: 22px;
      width: 22px;
      border: 4px solid var(--u-color-white);
      border-radius: 50%;
      background: var(--slider-color);
      cursor: pointer;
      margin-top: -14px;
      transform: translateY(-9px);
    }

    &::-ms-thumb {
      height: 22px;
      width: 22px;
      border: 4px solid var(--u-color-white);
      border-radius: 50%;
      background: var(--slider-color);
      cursor: pointer;
      margin-top: -14px;
    }

    &::-webkit-slider-runnable-track {
      width: 100%;
      height: 4px;
      cursor: pointer;
      background: transparent;
      border-radius: 12px;
      border: 1px solid var(--u-color-grey-200);
      transform: translate(-3px, -10px);
    }

    &:lang(el)::-webkit-slider-runnable-track,
    &:lang(ar)::-webkit-slider-runnable-track {
      transform: translate(-3px, -13px);
    }

    &::-moz-range-track {
      width: 100%;
      height: 4px;
      cursor: pointer;
      background: transparent;
      border-radius: 12px;
      border: 1px solid var(--u-color-grey-200);
      transform: translate(-2px, -9px);
    }

    &::-ms-track {
      width: 100%;
      height: 4px;
      cursor: pointer;
      background: transparent;
      border-color: transparent;
      border-width: 16px 0;
      color: transparent;
    }
  }
}

.slider-labels {
  display: flex;
  justify-content: space-between;
  color: var(--u-color-grey-500);

  &__label {
    cursor: pointer;
    flex: 0 0 calc(100% / var(--options-length));
    text-align: center;
    padding-top: 23px;

    &:first-child {
      text-align: left;
    }

    &:last-child {
      text-align: right;
    }

    &--active {
      color: var(--u-color-grey-900);
    }
  }
}

@media (min-width: 768px) {
  .colored-slider-input-wrap {
    &__input {
      &::-webkit-slider-runnable-track {
        transform: translate(-3px, -11px);
      }
    }
  }
}
</style>
